import {
  API_MAINCATEGORIES_REQUEST,
  API_MAINCATEGORIES_SUCCESS,
  API_SUBCATEGORIES_REQUEST,
  API_SUBCATEGORIES_SUCCESS,
  API_LASTARTICLES_REQUEST,
  API_LASTARTICLES_SUCCESS
} from '../actions/api'
import ApiCall from '@/utils/api'
//import Vue from 'vue'

const state = {
  maincategories: localStorage.getItem('maincateogires', false),
  lastarticles: localStorage.getItem('lastarticles', false)
}

const getters = {
  areMainCategoriesLoaded: state => state.areMainCategoriesLoaded,
  getMainCategories: state => JSON.parse(state.maincategories),
  areLastArticlesLoaded: state => state.areLastArticlesLoaded,
  getLastArticles: state => JSON.parse(state.lastarticles)
}

const actions = {
  [API_LASTARTICLES_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      if (state.areMainCategoriesLoaded) {
        resolve(state.lastarticles)
      } else {
        ApiCall({
          url: '/api/mylastarticles',
          method: 'get'
        })
          .then(resp => {
            let articles = resp.data

            localStorage.setItem('lastarticles', JSON.stringify(articles))
            commit(API_LASTARTICLES_SUCCESS, articles)
            resolve(articles)
          })
          .catch(err => {
            reject(err)
          })
      }
    })
  },
  [API_MAINCATEGORIES_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      ApiCall({
        url: '/maincategories',
        method: 'get'
      })
        .then(resp => {
          let maincategories = resp.data.sort((a, b) =>
            a.field_num > b.field_num ? 1 : -1
          )

          localStorage.setItem('maincateogires', JSON.stringify(maincategories))
          commit(API_MAINCATEGORIES_SUCCESS, maincategories)
          resolve(maincategories)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  [API_SUBCATEGORIES_REQUEST]: ({ commit }, category) => {
    return new Promise((resolve, reject) => {
      ApiCall({
        url: '/api/subcategories/' + category,
        method: 'get'
      })
        .then(resp => {
          let subcategories = resp.data.sort((a, b) =>
            a.field_num > b.field_num ? 1 : -1
          )

          localStorage.setItem(
            'subcategories_' + category,
            JSON.stringify(maincategories)
          )
          commit(API_SUBCATEGORIES_SUCCESS, maincategories)
          resolve(maincategories)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

const mutations = {
  [API_MAINCATEGORIES_SUCCESS]: (state, resp) => {
    state.areMainCategoriesLoaded = true
    state.maincategories = resp
  },
  [API_LASTARTICLES_SUCCESS]: (state, resp) => {
    state.areLastArticlesLoaded = true
    state.lastarticles = resp
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}