import { USER_REQUEST, USER_ERROR, USER_SUCCESS } from '../actions/user'
import ApiCall from '@/utils/api'
import Vue from 'vue'
import { AUTH_LOGOUT } from '../actions/auth'

const state = {
  status: 'success',
  profile: JSON.parse(localStorage.getItem('profile')),
  lang: localStorage.getItem('lang')
}

const getters = {
  getProfile: state => state.profile,
  getLanguage: state => (state.lang == 'de' ? 'de' : 'fr'),
  isProfileLoaded: state => !!state.profile.name
}

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(USER_REQUEST)
      ApiCall({
        url: '/data/api/get_user',
        method: 'post',
        formData: user
      })
        .then(resp => {
          let profile = {
            name: resp.data.name.split('@')[0],
            uid: resp.data.uid,
            email: resp.data.name,
            initials: resp.data.initials,
            firstname: resp.data.firstname,
            lastname: resp.data.lastname,
            lang: resp.data.language == 'de' ? 'de' : 'fr'
          }

          localStorage.setItem('profile', JSON.stringify(profile))
          localStorage.setItem('lang', JSON.stringify(profile.lang))

          commit(USER_SUCCESS, profile)

          resolve(resp)
        })
        .catch(err => {
          commit(USER_ERROR)
          dispatch(AUTH_LOGOUT)
          reject(err)
        })
    })
  }
}

const mutations = {
  [USER_REQUEST]: state => {
    state.status = 'loading'
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = 'success'
    Vue.set(state, 'profile', resp)
  },
  [USER_ERROR]: state => {
    state.status = 'error'
  },
  [AUTH_LOGOUT]: state => {
    state.profile = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
