<template>
    <v-layout
        class="pa-0"
        column
        justify-space-between
        style="width: 100% Important"
    >
        <div>
            <router-link :to="{ name: 'home' }" :key="$route.fullPath">
                <v-img
                    src="@/assets/img/logo.jpg"
                    contain
                    class="mr-5 ml-4 my-5"
                ></v-img>
            </router-link>

            <v-divider></v-divider>
            <AppNavigationCategories v-if="renderComponent" />
        </div>
    </v-layout>
</template>

<script>
import AppNavigationCategories from '@/components/AppNavigationCategories'

import { AUTH_LOGOUT } from '@/store/actions/auth'
import { Trans } from '@/plugins/Translation'

import api from '@/services/api'

export default {
    props: ['id'],
    data() {
        return {
            categories: [],
            loading: true,
            renderComponent: false,
        }
    },
    methods: {},
    computed: {
        currentLanguage() {
            return Trans.currentLanguage
        },
    },
    mounted() {
        if (this.$route.name != 'login') {
            this.renderComponent = true
        }
    },
    watch: {
        $route(to, from) {
            if (this.$route.name != 'login') {
                this.renderComponent = true
            }
        },
    },
    components: {
        AppNavigationCategories,
    },
}
</script>
<style lang="scss">
.skeleton_appnavigation {
    .v-skeleton-loader__card .v-skeleton-loader__image {
        border-radius: 5px;
    }
    .v-skeleton-loader__image {
        height: 55px;
    }
}
</style>
