<template>
    <div>
        <HomeHeader />
        <v-container class="pt-8 pb-16">
            <v-row justify="space-between">
                <v-col cols="9" class="pb-0">
                    <v-subheader
                        class="subtitle-1 font-weight-medium grey--text pa-0"
                        v-html="$t('article.main_categories')"
                    ></v-subheader>
                </v-col>
            </v-row>
            <v-row v-if="!loading">
                <v-col
                    cols="12"
                    :sm="6"
                    :md="4"
                    :lg="3"
                    :xl="2"
                    v-for="category in categories"
                    :key="category.id"
                >
                    <CategoryTeaser :category="category" />
                </v-col>
            </v-row>
            <v-row v-if="loading">
                <v-col
                    cols="12"
                    :sm="6"
                    :md="4"
                    :lg="3"
                    :xl="2"
                    v-for="i in 8"
                    :key="'categoryteaser' + i"
                >
                    <v-skeleton-loader
                        class="ma-0 pa-0 skeleton_categoryteaser"
                        type="image"
                        height="174"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
        </v-container>
        <!-- <v-divider></v-divider>
    <HomeLatestArticles /> -->
    </div>
</template>

<script>
import HomeHeader from '@/components/HomeHeader'
import CategoryTeaser from '@/components/CategoryTeaser'
import api from '@/services/api'

export default {
    name: 'home',
    data() {
        return {
            categories: [],
            articles: [],
            userpercent: 0,
            loading: true,
        }
    },
    mounted() {
        api.getMainCategories().then((response) => {
            this.categories = response.data
            console.log(this.categories)
            this.loading = false
        })
    },
    components: {
        HomeHeader,
        CategoryTeaser,
        // HomeLatestArticles
    },
}
</script>
<style lang="scss">
.skeleton_homesmallteaser {
    .v-skeleton-loader__card .v-skeleton-loader__image {
        border-radius: 5px;
    }
    .v-skeleton-loader__image {
        height: 86px;
    }
}
.skeleton_categoryteaser {
    .v-skeleton-loader__card .v-skeleton-loader__image {
        border-radius: 5px;
    }
    .v-skeleton-loader__image {
        height: 174px;
    }
}
.skeleton_search {
    border-radius: 50px;

    .v-skeleton-loader__card .v-skeleton-loader__image {
        border-radius: 50px;
    }
    .v-skeleton-loader__image {
        height: 48px;
    }
}
.search-container {
    border-radius: 50px;
}
</style>
