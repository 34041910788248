<template>
    <div>
        <div v-if="loadingCategory">
            <v-skeleton-loader
                tile
                class="pb-10 skeleton_headercategory"
                type="image"
                height="180"
            ></v-skeleton-loader>
        </div>
        <div v-if="!loadingCategory">
            <!-- <HeaderCategory category="category" /> -->
            <v-card tile class="white--text" :color="category.color">
                <v-row
                    justify="space-between"
                    class="pa-3"
                    style="position:relative;"
                >
                    <v-col
                        cols="12"
                        :sm="1"
                        class="pt-5 pb-5 d-flex justify-center headline"
                        :class="category.color + ' darken-2'"
                    >
                        <h3>{{ category.num }}</h3>
                    </v-col>
                    <v-col cols="12" :sm="11" class="pt-5 pb-12">
                        <v-container>
                            <v-row>
                                <v-col cols="12" class="d-flex">
                                    <v-container>
                                        <v-row class="justify-end px-0">
                                            <v-col
                                                cols="8"
                                                :sm="6"
                                                :md="6"
                                                :lg="3"
                                            >
                                                <v-text-field
                                                    class="ma-0 pa-0"
                                                    color="white"
                                                    v-model="query"
                                                    rounded
                                                    append-outer-icon="mdi-magnify"
                                                    filled
                                                    clear-icon="mdi-close-circle"
                                                    clearable
                                                    type="text"
                                                    @click:append-outer="
                                                        sendMessage
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col cols="12" :sm="6">
                                    <h1 class="pb-5 white--text">
                                        {{ category.name }}
                                    </h1>
                                    <div class="d-flex">
                                        <div class="d-flex align-center pr-5">
                                            <span
                                                class="font-weight-bold display-1 bigNumber"
                                            >
                                                {{ category.articles.length }}
                                            </span>
                                            <span
                                                class="white--text pl-3 title font-weight-medium"
                                            >
                                                {{
                                                    $tc(
                                                        'general.card_number',
                                                        category.articles.length
                                                    )
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </v-col>
                                <!-- <v-col
                                    cols="12"
                                    :sm="6"
                                    class="d-flex justify-end flex-column"
                                >
                                    <v-card-subtitle
                                        class="pa-0 pb-4 white--text"
                                    >
                                        {{ $t('general.progress') }}
                                    </v-card-subtitle>
                                    <v-card-text class="pa-0">
                                        <v-progress-linear
                                            class="progressbar_custom articlewhite"
                                            background-color="white"
                                            :color="category.color"
                                            rounded
                                            height="35"
                                            :value="score"
                                        >
                                            <template v-slot="{ value }">
                                                <strong
                                                    class="grey--text text--darken-2 d-flex align-center"
                                                    >{{ Math.ceil(value)
                                                    }}<span class="percent"
                                                        >%</span
                                                    ></strong
                                                >
                                            </template>
                                        </v-progress-linear>
                                    </v-card-text>
                                </v-col> -->
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-card>
        </div>
        <div v-if="!loadingCategory">
            <v-container class="py-16">
                <v-row>
                    <v-col
                        cols="12"
                        :sm="6"
                        :md="4"
                        :lg="3"
                        :xl="2"
                        v-for="(article, i) in category.articles"
                        :key="article.id + i"
                        :article="article"
                    >
                        <v-container class="px-5 py-0">
                            <ArticleTeaser :article="article" />
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
            <CategorySubcategory
                v-for="subcategory in category.subcategories"
                :key="'subcat_' + subcategory.id"
                :subcategory="subcategory"
            />
        </div>

        <div v-if="loadingCategory">
            <v-container v-for="i in 8" :key="i" class="mb-10">
                <v-row justify="space-between">
                    <v-col
                        cols="12"
                        :sm="6"
                        :md="4"
                        :lg="3"
                        :xl="2"
                        class="pb-0"
                    >
                        <v-skeleton-loader
                            class="pb-3"
                            type="text"
                        ></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        :sm="6"
                        :md="4"
                        :lg="3"
                        :xl="2"
                        v-for="i in 8"
                        :key="i"
                    >
                        <v-skeleton-loader
                            class=""
                            type="card"
                        ></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-divider></v-divider>

        <v-container>
            <v-row justify="space-between" class="px-5 pt-7">
                <v-col cols="12" class="pb-0">
                    <v-subheader
                        class="subtitle-1 font-weight-medium grey--text pa-0"
                        v-html="$t('article.main_categories')"
                    ></v-subheader>
                </v-col>
            </v-row>
            <v-row v-if="!loadingCategory" class="px-5">
                <v-col
                    cols="12"
                    :sm="6"
                    :md="4"
                    :lg="3"
                    :xl="2"
                    v-for="category in categories"
                    :key="category.id"
                >
                    <CategoryTeaserSelected
                        :category="category"
                        v-if="category.id === id"
                    />
                    <CategoryTeaser
                        :category="category"
                        v-if="category.id !== id"
                    />
                </v-col>
            </v-row>
            <v-row v-if="loadingCategory">
                <v-col
                    cols="12"
                    :sm="6"
                    :md="4"
                    :lg="3"
                    :xl="2"
                    v-for="i in 8"
                    :key="i"
                >
                    <v-skeleton-loader
                        class="ma-0 pa-0 skeleton_categoryteaser"
                        type="image"
                        height="174"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import CategorySubcategory from '@/components/CategorySubcategory'
import CategoryTeaser from '@/components/CategoryTeaser'
import ArticleTeaser from '@/components/ArticleTeaser'
import CategoryTeaserSelected from '@/components/CategoryTeaserSelected'
import HeaderCategory from '@/components/HeaderCategory'

import api from '@/services/api'

export default {
    props: ['id'],
    data() {
        return {
            category: Object,
            subcategories: [],
            categories: [],
            loadingCategory: true,
            articles: [],
            query: '',
            score: 0,
        }
    },

    methods: {
        sendMessage() {
            this.$router.push('/search?query=' + this.query)
        },
    },
    created() {
        api.getMainCategory(this.id).then((response) => {
            this.category = response.data

            // api.getScore(this.$store.getters.getProfile.uid).then(
            //     (response) => {
            //         this.score = response.data.categories[this.category.id]
            //             ? response.data.categories[this.category.id].score
            //             : 0
            //     }
            // )
            api.getMainCategories().then((response) => {
                this.categories = response.data
                this.loadingCategory = false
            })
        })
    },
    computed: {},
    components: {
        CategorySubcategory,
        CategoryTeaser,
        CategoryTeaserSelected,
        ArticleTeaser,
        HeaderCategory,
    },
}
</script>
<style lang="scss">
@import 'src/assets/css/progressbar.scss';

.number {
    font-size: 24px;
    font-family: 'Lora', serif !important;
}

.skeleton_headercategory {
    .v-skeleton-loader__image {
        height: 283px;
    }
}

.skeleton_search {
    border-radius: 50px;

    .v-skeleton-loader__card .v-skeleton-loader__image {
        border-radius: 50px;
    }
    .v-skeleton-loader__image {
        height: 48px;
    }
}
.search-container {
    border-radius: 50px;
}
.v-text-field--filled > .v-input__control > .v-input__slot {
    min-height: 40px;
}

.v-text-field--full-width .v-input__prepend-outer,
.v-text-field--full-width .v-input__prepend-inner,
.v-text-field--full-width .v-input__append-inner,
.v-text-field--full-width .v-input__append-outer,
.v-text-field--enclosed .v-input__prepend-outer,
.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
    margin-top: 8px;
}
</style>
