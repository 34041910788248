<template>
    <div>
        <div v-if="!loading">
            <ArticleHeader
                :article="article"
                :previous="previous"
                :next="next"
            />
        </div>
        <v-skeleton-loader
            class="mx-auto skeleton_header"
            type="image"
            v-if="loading"
            min-height="274"
        >
        </v-skeleton-loader>

        <v-container class="mt-n12" v-if="!loading">
            <div :class="article.category.color + '_color'">
                <v-card tile class="pt-md-6 px-lg-12 pb-12">
                    <v-container class="pa-0">
                        <v-row class="pt-5 pt-md-10">
                            <v-col cols="12" md="7" class="px-10">
                                <h1
                                    v-html="article.title"
                                    :class="article.category.color + '--text'"
                                ></h1>
                            </v-col>
                            <v-col cols="12" md="5" class="px-10 py-0">
                                <p
                                    class="text-left text-md-right mb-0 mb-md-10"
                                >
                                    <span class="grey--text body-2">
                                        {{ $t('general.card_number') }}
                                    </span>
                                    <span
                                        class="font-weight-bold display-1 pl-4 bigNumber"
                                        :class="
                                            article.category.color + '--text'
                                        "
                                    >
                                        {{ article.position }}/{{
                                            article.nbarticles
                                        }}
                                    </span>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex flex-column-reverse flex-md-row">
                            <v-col
                                cols="12"
                                :md="8"
                                class="px-10 left-col-article"
                            >
                                <div
                                    ref="anchor_mainobj"
                                    v-if="objectives.main"
                                >
                                    <h2
                                        class="mb-2 mt-0"
                                        v-html="$t('article.main_obj')"
                                    ></h2>
                                    <ul>
                                        <li
                                            v-for="(obj, i) in objectives.main"
                                            :key="'mainobj' + i"
                                            v-html="obj.title"
                                            :class="
                                                article.category.color +
                                                    '--bullet'
                                            "
                                        ></li>
                                    </ul>
                                </div>

                                <div
                                    id="anchor_secondobj"
                                    v-if="objectives.secondary"
                                >
                                    <h2
                                        class="mt-10 mb-2"
                                        v-html="$t('article.second_obj')"
                                    ></h2>
                                    <ul>
                                        <li
                                            v-for="(obj,
                                            i) in objectives.secondary"
                                            :key="'secobj' + i"
                                            v-html="obj.title"
                                            :class="
                                                article.category.color +
                                                    '--bullet'
                                            "
                                        ></li>
                                    </ul>
                                </div>

                                <div ref="anchor_content" v-if="article.body">
                                    <h2
                                        class="mt-10 mb-2"
                                        v-html="$t('article.fiche_content')"
                                    ></h2>
                                    <div
                                        v-html="article.body"
                                        class="article-text"
                                    ></div>
                                </div>

                                <div
                                    ref="anchor_content"
                                    v-for="(section, k) in article.sections"
                                    :key="k"
                                >
                                    <h2
                                        class="mt-10 mb-2"
                                        v-html="section.title"
                                    ></h2>
                                    <div
                                        v-html="section.body"
                                        class="article-text"
                                    ></div>
                                </div>

                                <div ref="anchor_doc" v-if="article.annexes">
                                    <h2
                                        class="mt-10 mb-4"
                                        v-html="$t('article.useful_doc')"
                                    ></h2>
                                    <v-btn
                                        v-for="(annexe, i) in article.annexes"
                                        :key="'annexe_' + i"
                                        class="btn-column-article d-flex py-8 px-0"
                                        :class="
                                            article.category.color + '--text'
                                        "
                                        :href="annexe.url"
                                        target="_blank"
                                    >
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    cols="11"
                                                    class="text-truncate"
                                                >
                                                    {{ annexe.title }}
                                                </v-col>
                                                <v-col
                                                    cols="1"
                                                    class="d-flex justify-center pa-0"
                                                >
                                                    <v-icon right class="ma-0"
                                                        >mdi-file</v-icon
                                                    >
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-btn>
                                </div>

                                <div ref="anchor_link" v-if="article.links">
                                    <h2
                                        class="mt-10 mb-4"
                                        v-html="$t('article.useful_link')"
                                    ></h2>
                                    <v-btn
                                        v-for="(link, i) in article.links"
                                        :key="'link_' + i"
                                        class="btn-column-article d-flex py-8 px-0"
                                        :class="
                                            article.category.color + '--text'
                                        "
                                        :href="link.url"
                                        target="_blank"
                                    >
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    cols="11"
                                                    class="text-truncate"
                                                >
                                                    {{ link.title }}
                                                </v-col>
                                                <v-col
                                                    cols="1"
                                                    class="d-flex justify-center pa-0"
                                                >
                                                    <v-icon right class="ma-0"
                                                        >mdi-link</v-icon
                                                    >
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-btn>
                                </div>

                                <div ref="anchor_video" v-if="article.videos">
                                    <h2
                                        class="mt-10 mb-4"
                                        v-html="$t('article.video')"
                                    ></h2>
                                    <v-container>
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                xl="4"
                                                v-for="(item,
                                                i) in article.videos"
                                                :key="'video_' + i"
                                            >
                                                <div
                                                    class="videoarticle-responsive"
                                                >
                                                    <youtube
                                                        v-if="
                                                            item.type ==
                                                                'youtube'
                                                        "
                                                        :video-id="item.id"
                                                    ></youtube>
                                                    <iframe
                                                        v-if="
                                                            item.type ==
                                                                'switch'
                                                        "
                                                        :src="
                                                            'https://tube.switch.ch/embed/' +
                                                                item.id
                                                        "
                                                        frameborder="0"
                                                        webkitallowfullscreen
                                                        mozallowfullscreen
                                                        allowfullscreen
                                                    ></iframe>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </div>
                                <div
                                    ref="anchor_subarticles"
                                    v-if="article.subarticles"
                                >
                                    <h2
                                        class="mt-10 mb-4"
                                        v-html="$t('article.subarticles')"
                                    ></h2>
                                    <v-container>
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                xl="4"
                                                v-for="(item,
                                                i) in article.subarticles"
                                                :key="'subarticles_' + i"
                                            >
                                                <ArticleSmallTeaser
                                                    :article="item"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </div>
                            </v-col>

                            <v-col cols="12" :md="4" class="px-10">
                                <div class="px-md-4">
                                    <v-responsive
                                        :aspect-ratio="1"
                                        v-if="articleImg"
                                        class="mb-9"
                                    >
                                        <v-img
                                            :src="articleImg"
                                            aspect-ratio="1"
                                            contain
                                        >
                                        </v-img>
                                    </v-responsive>

                                    <!-- <v-btn
                    block
                    class="my-5 btn-article"
                    :class="article.category.color + '--text'"
                  >
                    Partager<v-icon right>mdi-share-variant</v-icon>
                  </v-btn> -->
                                    <!-- <v-btn
                    block
                    class="btn-article white--text"
                    :class="article.category.color"
                    v-on:click="markAsFavorite(article.id)"
                  >
                    Epingler cette fiche<v-icon right>mdi-bookmark</v-icon>
                  </v-btn> -->

                                    <v-btn
                                        v-if="article.pdf"
                                        block
                                        class="btn-article white--text px-0 mb-12 mt-12"
                                        :class="article.category.color"
                                        :href="article.pdf"
                                        target="_blank"
                                    >
                                        {{ $t('article.download_pdf')
                                        }}<v-icon right>mdi-file-pdf</v-icon>
                                    </v-btn>

                                    <div class="mt-3">
                                        <v-btn
                                            v-if="article.body"
                                            width="100%"
                                            :class="
                                                article.category.color +
                                                    '--text'
                                            "
                                            class="justify-start btn-anchor px-2"
                                            @click="goto('anchor_content')"
                                            v-html="$t('article.fiche_content')"
                                        ></v-btn>
                                        <v-btn
                                            v-if="article.annexes"
                                            width="100%"
                                            :class="
                                                article.category.color +
                                                    '--text'
                                            "
                                            class="justify-start btn-anchor px-2"
                                            @click="goto('anchor_doc')"
                                            v-html="$t('article.useful_doc')"
                                        ></v-btn>
                                        <v-btn
                                            v-if="article.links"
                                            width="100%"
                                            :class="
                                                article.category.color +
                                                    '--text'
                                            "
                                            class="justify-start btn-anchor px-2"
                                            @click="goto('anchor_link')"
                                            v-html="$t('article.useful_link')"
                                        ></v-btn>
                                        <v-btn
                                            v-if="article.videos"
                                            width="100%"
                                            :class="
                                                article.category.color +
                                                    '--text'
                                            "
                                            class="justify-start btn-anchor px-2"
                                            @click="goto('anchor_video')"
                                            v-html="$t('article.video')"
                                        ></v-btn>
                                        <v-btn
                                            v-if="
                                                objectives.main ||
                                                    objectives.secondary
                                            "
                                            width="100%"
                                            :class="
                                                article.category.color +
                                                    '--text'
                                            "
                                            class="justify-start btn-anchor px-2"
                                            @click="goto('anchor_validateobj')"
                                            v-html="$t('article.obj_validate')"
                                        ></v-btn>
                                    </div>
                                    <div class="mt-12">
                                        <v-chip-group column>
                                            <router-link
                                                class="chip"
                                                v-for="tag in article.tags"
                                                :key="'tag_' + tag.id"
                                                :to="{
                                                    name: 'tag',
                                                    params: { id: tag.id },
                                                }"
                                            >
                                                <v-chip
                                                    class="white--text"
                                                    :color="
                                                        article.category.color
                                                    "
                                                >
                                                    {{ tag.name }}
                                                </v-chip>
                                            </router-link>
                                        </v-chip-group>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-divider style="border-color:rgba(0,0,0,0.1);"></v-divider>
                <v-divider style="border-color:rgba(0,0,0,0.1);"></v-divider>
                <v-card
                    tile
                    class="pt-3 pb-12 mb-12 px-md-12"
                    ref="anchor_validateobj"
                >
                    <v-container class="pa-0 px-10 down-col">
                        <v-row>
                            <v-col
                                cols="12"
                                v-if="objectives.main || objectives.secondary"
                            >
                                <h2 v-html="$t('article.obj_validate')"></h2>
                            </v-col>
                            <v-col cols="12" :md="12" v-if="objectives.main">
                                <h3
                                    :class="article.category.color + '--text'"
                                    class="mb-2"
                                    v-html="$t('article.main_obj')"
                                ></h3>
                                <ul>
                                    <li
                                        style="width:45%;float:left;"
                                        v-for="(obj, id) in objectives.main"
                                        :key="'amainobj' + id"
                                        :class="
                                            article.category.color + '--bullet'
                                        "
                                    >
                                        <v-checkbox
                                            @change="switchObjectif(id)"
                                            :label="obj.title"
                                            :color="article.category.color"
                                            :input-value="obj.status"
                                            hide-details
                                        ></v-checkbox>
                                    </li>
                                </ul>
                            </v-col>
                            <!-- <v-col cols="12" :md="6" v-if="objectives.main">
                <h3 :class="article.category.color + '--text'" class="mb-8">
                  &nbsp;
                </h3>
                <ul>
                  <li
                    v-for="(obj, id) in objectives.main"
                    :key="'amainobj' + id"
                    :class="article.category.color + '--bullet'"
                  >
                    <v-checkbox
                      @change="switchObjectif(id)"
                      :label="obj.title"
                      :color="article.category.color"
                      :input-value="obj.status"
                      hide-details
                    ></v-checkbox>
                  </li>
                </ul> -->
                            <!-- <h3
                  :class="article.category.color + '--text'"
                  class="mb-2"
                  v-html="$t('article.second_obj')"
                ></h3>
                <ul>
                  <li
                    v-for="(obj, id) in objectives.secondary"
                    :key="'asecobj' + id"
                    :class="article.category.color + '--bullet'"
                  >
                    <v-checkbox
                      @change="switchObjectif(id)"
                      :label="obj.title"
                      :color="article.category.color"
                      :input-value="obj.status"
                      value="Lorem_ipsum"
                      hide-details
                    ></v-checkbox>
                  </li>
                </ul> -->
                            <!-- </v-col> -->

                            <!-- <div class="px-4 py-4">
                <v-btn
                  block
                  class="btn-article white--text"
                  :color="article.category.color"
                  v-on:click="markAsFavorite()"
                >
                  > Valider tous les objectifs<v-icon right>mdi-check</v-icon>
                </v-btn>
              </div> -->

                            <!-- <v-col cols="12" class="px-0 py-4 pt-8">
                                <h2 class="px-4">
                                    {{ $t('general.category_progress') }} "{{
                                        article.maincategory.title
                                    }}"
                                </h2>
                                <v-card-text class="pb-0">
                                    <v-progress-linear
                                        class="progressbar_custom article"
                                        background-color="grey lighten-2"
                                        :color="article.category.color"
                                        rounded
                                        height="45"
                                        :value="score"
                                    >
                                        <template v-slot="{ value }">
                                            <strong
                                                class="grey--text text--darken-2"
                                                >{{ Math.ceil(value)
                                                }}<span class="percent"
                                                    >%</span
                                                ></strong
                                            >
                                        </template>
                                    </v-progress-linear>
                                </v-card-text>
                            </v-col> -->
                        </v-row>
                    </v-container>
                </v-card>
            </div>
        </v-container>

        <div v-if="loading">
            <v-container class="mt-n12 pa-0 white relative skeleton_card">
                <v-skeleton-loader class="py-6 px-md-12 mb-12" type="card">
                    <v-container class="pa-0">
                        <v-row class="d-flex flex-column-reverse flex-md-row">
                            <v-col cols="12" :md="8" class="px-10">
                                <v-skeleton-loader
                                    class="py-6 px-md-1 ma-0"
                                    type="list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
                                >
                                </v-skeleton-loader>
                            </v-col>

                            <v-col cols="12" :md="4" class="px-10">
                                <div class="px-4">
                                    <v-skeleton-loader
                                        class="py-6 px-md-2 ma-0"
                                        type="button, button"
                                    >
                                    </v-skeleton-loader>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-skeleton-loader>
            </v-container>

            <!-- <v-container>
        <v-row>
          <v-col cols="12" md="8">
            <v-skeleton-loader
              class="mb-6"
              type="list-item-three-line, list-item-three-line"
            ></v-skeleton-loader>
          </v-col>

          <v-col cols="12" md="4">
            <v-skeleton-loader class="mb-6" type="actions"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container> -->
        </div>
    </div>
</template>

<script>
import api from '@/services/api'
import ArticleHeader from 'components/ArticleHeader'
import ArticleSmallTeaser from '@/components/ArticleSmallTeaser'

export default {
    props: ['id'],
    data() {
        return {
            score: 0,
            rating: 2,
            article: {},
            previous: null,
            objectives: [],
            next: null,
            articleImg: '',
            loading: true,
            uid: this.$store.state.user.profile.profile_uid,
        }
    },

    created() {
        api.getArticle(this.id).then((response) => {
            this.article = response.data.article
            console.log(this.article.logo)
            this.articleImg = this.article.logo
            this.previous = response.data.previous
            this.next = response.data.next
            this.loading = false
            // api.setLatestArticles(
            //     this.$store.getters.getProfile.uid,
            //     this.article.id
            // )
            // api.getScore(this.$store.getters.getProfile.uid).then(
            //     (response) => {
            //         this.score = response.data.categories[
            //             this.article.maincategory.id
            //         ]
            //             ? response.data.categories[this.article.maincategory.id]
            //                   .score
            //             : 0
            //     }
            // )
            // api.getObjectives(this.id, this.$store.getters.getProfile.uid).then(
            //     (response) => {
            //         this.objectives = response.data
            //     }
            // )
        })
    },

    methods: {
        switchObjectif(id) {
            api.switchObjectif(
                this.id,
                id,
                this.$store.getters.getProfile.uid
            ).then((response) => {
                this.score = response.data
            })
        },
        markAsFavorite(id) {
            api.switchFavorite(this.id, this.$store.getters.getProfile.uid)
        },
        goto(refName) {
            var element = this.$refs[refName]
            if (this.typeOf(element) == 'object') {
                element = this.$refs[refName]['$el']
            }

            element.scrollIntoView({ behavior: 'smooth' })
        },
        typeOf(obj) {
            return {}.toString
                .call(obj)
                .split(' ')[1]
                .slice(0, -1)
                .toLowerCase()
        },
    },

    components: {
        ArticleHeader,
        ArticleSmallTeaser,
    },
}
</script>

<style lang="scss">
@import 'src/assets/css/color.scss';
@import 'src/assets/css/article_color.scss';
@import 'src/assets/css/progressbar.scss';

.videoarticle-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

h1 {
    word-break: break-word !important;
}

.v-divider {
}

.btn-anchor {
    border-radius: 0px;
    background-color: transparent !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.btn-column-article {
    border-radius: 0;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: transparent !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);

    &:first-of-type {
        content: '';
        border-top: 2px solid rgba(0, 0, 0, 0.1);
    }

    .v-btn__content {
        justify-content: space-between;
        display: contents;
    }
}

.btn-article {
    .v-btn__content {
        justify-content: space-between;
        display: contents;
    }
}

.chip {
    text-decoration: none;
}

.down-col {
    h1 {
        font-size: 26px;
    }

    h2 {
        color: #1a1a1a;
        font-family: 'Roboto', serif !important;
        font-weight: 500;
        font-size: 20px;
    }

    h3 {
        font-family: 'Roboto', serif !important;
        font-size: 16px;
    }

    ul {
        list-style: none;
        padding: 0 !important;

        li {
            .v-input--selection-controls.v-input {
                padding-top: 5px;
                margin-top: 5px;
            }
        }
    }
}

.left-col-article {
    h1 {
        font-size: 26px;
    }

    h2 {
        color: #1a1a1a;
        font-family: 'Roboto', serif !important;
        font-weight: 500;
        font-size: 20px;
    }

    h3 {
        font-family: 'Roboto', serif !important;
        font-size: 16px;
    }

    ul {
        list-style: none;

        li {
            color: #545454;

            &:before {
                content: '\2022';
                font-size: 22px;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }

            &.bluegreycat--bullet:before {
                color: $bluegreycat;
            }
            &.redcat--bullet:before {
                color: $redcat;
            }
            &.kakicat--bullet:before {
                color: $kakicat;
            }
            &.bluecat--bullet:before {
                color: $bluecat;
            }
            &.yellowcat--bullet:before {
                color: $yellowcat;
            }
            &.maladiesPsychiques--bullet:before {
                color: $maladiesPsychiques;
            }
            &.violetcat--bullet:before {
                color: $violetcat;
            }
            &.maladieChroniques--bullet:before {
                color: $maladieChroniques;
            }
            &.greycat--bullet:before {
                color: $greycat;
            }
        }
    }

    .article-text {
        color: #545454;
    }
}

.article-text {
    h2 {
        font-weight: 600;
        // solor: 'articleCategory.field_color[0].value';
    }
    h3 {
        font-weight: 600;
        font-size: 20px;
    }
    h4 {
        font-size: 18px;
        font-weight: 600;
    }
    h5 {
        font-size: 15px;
        font-weight: 600;
    }
    h6 {
        font-size: 15px;
        font-weight: 500;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Lora', serif;
    }
    p {
        font-size: 15px;
    }
    ul {
        margin-bottom: 16px;

        li {
            font-size: 15px;
        }
    }

    ol {
        margin-bottom: 16px;

        li {
            font-size: 15px;
        }
    }
    a {
        color: red;
        transition: all 0.2s;
        &:hover {
            color: black;
        }
    }
}
.link_list {
    // border: 1px solid red;
}

.triangle {
    &.corner_header {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        font-size: 24px;
        height: 35px;
        font-family: 'Lora', serif !important;
    }
}

.skeleton_header {
    .v-skeleton-loader__image {
        height: 280px;
    }
}
.skeleton_card {
    z-index: 20;
    position: relative;
}

.v-skeleton-loader__button {
    width: 100%;
    margin-bottom: 10px;
}
.progresse_reactive {
    border-radius: 5px;
}
</style>
