<template>
  <v-container>
    <v-row justify="space-between">
      <v-col cols="12" class="pb-0">
        <v-subheader class="title font-weight-medium px-5 py-0">
          {{ subcategory.name }}
        </v-subheader>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        :sm="6"
        :md="4"
        :lg="3"
        :xl="2"
        v-for="(article, i) in subcategory.articles"
        :key="article.id + i"
        :article="article"
      >
        <ArticleTeaser :article="article" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ArticleTeaser from '@/components/ArticleTeaser'

export default {
  props: {
    subcategory: Object
  },
  data() {
    return {
      articles: []
    }
  },
  components: {
    ArticleTeaser
  }
}
</script>
