<template>
  <v-card :to="{ name: 'article', params: { id: article.id } }">
    <v-container class="pa-0">
      <div
        class="triangle corner_articlesmallteaser mb-0 font-weight-black white--text"
        :class="article.color + '_color'"
      ></div>
      <div class="d-flex flex-no-wrap justify-space-between">
        
        <v-row justify="space-between" class="px-7 py-2">
          <v-col cols="10" class="pa-0 d-flex align-center">
            <div>
              <p
                class="mb-0 subtitle-2"
                :class="article.color + '--text'"
              >
                {{ article.title }}
              </p>
            </div>
          </v-col>

          <v-col cols="2" class="pa-0 d-flex align-center">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-icon medium :color="article.color"
                  >mdi-arrow-right</v-icon
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    article: Object
  }
}
</script>

<style lang="scss">
.triangle {
  &.corner_articlesmallteaser {
    position: absolute;
    top: 0px;
    left: 0px;
    height:25px;
    z-index: 1;
    font-size: 12px;
    font-family: 'Lora', serif !important;
  }
}
</style>
