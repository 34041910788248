export const API_MAINCATEGORIES_REQUEST = 'API_MAINCATEGORIES_REQUEST'
export const API_MAINCATEGORIES_SUCCESS = 'API_MAINCATEGORIES_SUCCESS'

export const API_SUBCATEGORIES_REQUEST = 'API_SUBCATEGORIES_REQUEST'
export const API_SUBCATEGORIES_SUCCESS = 'API_SUBCATEGORIES_SUCCESS'

export const API_LASTARTICLES_REQUEST = 'API_LASTARTICLES_REQUEST'
export const API_LASTARTICLES_SUCCESS = 'API_LASTARTICLES_SUCCESS'

export const API_ARTICLESBYCAT_REQUEST = 'API_LASTARTICLES_REQUEST'
export const API_ARTICLESBYCAT_SUCCESS = 'API_LASTARTICLES_SUCCESS'
