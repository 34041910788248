import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        bluegreycat: '#96A0BE',
        redcat: '#E61400',
        kakicat: '#C3C850',
        bluecat: '#4191B4',
        yellowcat: '#FAD200',
        maladiesPsychiques: '#FF9600',
        violetcat: '#A05087',
        maladieChroniques: '#3C9646',
        greycat: '#555555'
      }
    }
  }
})
