<template>
    <v-card :to="{ name: 'category', params: { id: category.id } }">
        <v-container class="pt-0 pb-4 px-0">
            <div
                class="number mb-0 px-4 py-2 font-weight-black white--text"
                :class="category.color + ' darken-2'"
            >
                {{ category.num }}
            </div>
            <v-row justify="space-between">
                <v-col cols="12" class="py-0">
                    <v-list-item>
                        <v-list-item-content class="align-end">
                            <v-progress-linear
                                class="my-6 progressbar_teaser rounded-pill"
                                background-color="transparent"
                                :color="category.color"
                                height="20"
                                :value="
                                    categoriesscore[category.id]
                                        ? categoriesscore[category.id].score
                                        : 0
                                "
                            >
                                <!-- <template v-slot="{ value }">
                  <strong class="white--text pl-5"
                    >{{ Math.ceil(value)
                    }}<span class="percent">%</span></strong
                  >
                </template> -->
                            </v-progress-linear>

                            <v-container class="pa-0">
                                <v-row no-gutters class="align-center">
                                    <v-col
                                        cols="11"
                                        :class="
                                            category.color +
                                                '--text' +
                                                ' subtitle-2' +
                                                ' d-inline-block' +
                                                ' text-truncate' +
                                                ' py-0'
                                        "
                                    >
                                        {{ category.name }}
                                    </v-col>
                                    <v-col cols="1" class="py-0">
                                        <v-icon medium :color="category.color">
                                            mdi-chevron-right
                                        </v-icon>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import api from '@/services/api'
export default {
    props: {
        category: Object,
    },
    data() {
        return {
            categoriesscore: Object,
        }
    },
    mounted() {
        api.getScore(this.$store.getters.getProfile.uid).then((response) => {
            this.categoriesscore = response.data.categories
        })
    },
}
</script>

<style lang="scss">
@import 'src/assets/css/progressbar.scss';
</style>
