<template>
  <v-card tile outlined class="py-10 test-color"
    ><v-container>
      <v-list-item class="pl-0">
              <v-list-item-content>
                <v-card-title class="white--text pa-0">
                  <h1 v-html="quiz.name" class="title-header"></h1>
                </v-card-title>
              </v-list-item-content>
            </v-list-item>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props:['quiz'],
  data() {
    return {
      
    }
  },
  created() {
    
  }
}
</script>

<style lang="scss">
@import 'src/assets/css/progressbar.scss';

.triangle {
  .corner_header {
    position: absolute;
    top: 0px;
    left: 0px;
    height:35px;
    z-index: 1;
    font-size: 20px;
    font-family: 'Lora', serif !important;
  }
}

.test-color {
  position: relative;
  background-size: 2000% 2000% !important;
  color: #fff;
  -webkit-animation: GradientAnimation 140s ease infinite;
  -moz-animation: GradientAnimation 140s ease infinite;
  animation: GradientAnimation 140s ease infinite;
  width: auto;
  margin: 0;
  border-top: 0px solid transparent !important;
  background: linear-gradient(
    270deg,
    #4191b4,
    #3c9646,
    #ff9600,
    #e61400,
    #c3c850,
    #fad200,
    #96a0be,
    #a05087
  );
}
@-webkit-keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
