<template>
    <v-card
        class="d-inline-block"
        link
        width="100%"
        :to="{ name: 'category', params: { id: category.id } }"
        :key="'/category/' + category.id"
    >
        <v-container class="py-2" :class="category.color">
            <v-row>
                <v-col
                    cols="1"
                    class="px-0 d-flex align-start justify-center"
                    :class="category.color + ' darken-2'"
                >
                    <p class="number-nav font-weight-black white--text ma-0">
                        {{ category.num }}
                    </p>
                </v-col>

                <v-col cols="9">
                    <p class="my-2 white--text subtitle-2">
                        {{ category.name }}
                    </p>
                </v-col>

                <v-col cols="2" class="pa-0 d-flex justify-center align-center">
                    <p class="ma-0">
                        <v-icon medium color="white">
                            mdi-chevron-down
                        </v-icon>
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
    props: {
        category: Object,
    },
}
</script>

<style lang="scss">
.number-nav {
    font-size: 12px;
    font-family: 'Lora', serif !important;
    text-align: center;
}
</style>
