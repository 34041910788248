<template>
    <v-card class="flex py-0" tile>
        <v-col cols="12" align="center">
            <a href="https://www.reper.ch" target="_blank">
                REPER - 1700 Fribourg
            </a>
        </v-col>
    </v-card>
</template>

<script>
export default {
    data: () => ({
        icons: ['mdi-facebook-box', 'mdi-twitter-box', 'mdi-instagram'],
    }),
}
</script>

<style lang="scss">
.v-list-item {
    min-height: 25px;
}
a {
    color: #494a75 !important;
    text-decoration: none;
    font-size: 12px;
}
</style>
