<template>
  
  
    <v-card
      :to="{ name: 'article', params: { id: article.id } }"
      class="pa-6"
    >
      <v-responsive :aspect-ratio="1/1">
        <h3 class="pb-2 body-1 font-weight-bold" :class="article.color + '--text'">
          {{ article.header }}
        </h3>
        <p class="body-1" :class="article.color + '--text'">
          {{ article.title }}
        </p>

      </v-responsive>
    </v-card>
    

</template>

<script>
export default {
  props: {
    article: Object
  }
}
</script>

<style lang="scss">
.v-card__actions {
  height: 200px;
}
</style>
