<template>
  <v-card tile outlined class="py-10 test-color"
    ><v-container>
      <div
        class="triangle corner_header mb-0 font-weight-black white--text home"
      ></div>
      <v-row justify="space-between">
        <v-col cols="12" :sm="6" class="mt-12 pt-12">
            <h1 class="pb-5 white--text">
                {{ 'Profile' }}
              </h1>
        </v-col>
        <v-col cols="12" :sm="6">
          <v-row>
            <v-col cols="6">
              
            </v-col>
            <v-col cols="6">
              <div class="d-flex flex-no-wrap justify-end align-center">
                <div class="pr-5 white--text">
                  <p
                    class="ma-0 text-right"
                    v-text="
                      $store.getters.getProfile.firstname +
                        ' ' +
                        $store.getters.getProfile.lastname
                    "
                  ></p>
                  <p class="ma-0 text-right"></p>
                </div>
                <v-avatar color="grey darken-3" size="60">
                  <span class="white--text headline">{{
                    $store.getters.getProfile.initials
                  }}</span>
                </v-avatar>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import api from '@/services/api'
export default {
  data() {
    return {
      profile: Object,
      score: 0,
      totalarticle: 0,
      query: ''
    }
  },
  created() {
    api.getScore(this.$store.getters.getProfile.uid).then(response => {
      this.score = response.data
    })
    api.getTotalArticles().then(response => {
      this.totalarticle = response.data
    })
  },

  methods: {
    sendMessage() {
      this.$router.push('/search?query=' + this.query)
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/css/progressbar.scss';

.triangle {
  .corner_header {
    position: absolute;
    top: 0px;
    left: 0px;
    height:30px;
    z-index: 1;
    font-size: 20px;
    font-family: 'Lora', serif !important;

  }
}

.test-color {
  position: relative;
  background-size: 2000% 2000% !important;
  color: #fff;
  -webkit-animation: GradientAnimation 140s ease infinite;
  -moz-animation: GradientAnimation 140s ease infinite;
  animation: GradientAnimation 140s ease infinite;
  width: auto;
  margin: 0;
  border-top: 0px solid transparent !important;
  background: linear-gradient(
    270deg,
    #4191b4,
    #3c9646,
    #ff9600,
    #e61400,
    #c3c850,
    #fad200,
    #96a0be,
    #a05087
  );
}
@-webkit-keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
