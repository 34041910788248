import ApiCall from '@/utils/api'
import ApiNoCacheCall from '@/utils/apinocache'
const v = 1

export default {
    auth(credentials) {
        const formData = new FormData()
        formData.append('grant_type', 'password')
        formData.append('client_id', 'fd9cc4b2-8afd-42f5-9789-384732befce8')
        formData.append('client_secret', 'yla-aasv/*ica)')
        formData.append('username', credentials.email)
        formData.append('password', credentials.password)
        formData.append('scope', 'api')

        return axios.post('//tectum.test/oauth/token', formData, {
            useCredentails: true,
        })
    },
    getCurrentUser(uid) {
        return new Promise((resolve, reject) => {
            let data = localStorage.getItem(v + '_current_user' + uid)

            if (data) {
                resolve(JSON.parse(data))
                return
            }
            ApiCall({ url: '/rest/api/user?_format=json', method: 'get' })
                .then((response) => {
                    localStorage.setItem(
                        v + '_current_user' + uid,
                        JSON.stringify(response)
                    )
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getArticlesByCategory(category) {
        return new Promise((resolve, reject) => {
            ApiCall({
                url: '/api/articlesbycategory/' + category,
                method: 'get',
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getMainCategories() {
        return new Promise((resolve, reject) => {
            ApiCall({ url: '/maincategories', method: 'get' })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getObjectives(article, user) {
        return new Promise((resolve, reject) => {
            ApiNoCacheCall({
                url: '/api/getobjectives/' + article + '/' + user,
                method: 'get',
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getTotalArticles() {
        return new Promise((resolve, reject) => {
            ApiCall({ url: '/totalarticles', method: 'get' })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getSubCategories(parent) {
        return new Promise((resolve, reject) => {
            let data = localStorage.getItem(v + '_subcategory_' + parent)

            if (data) {
                resolve(JSON.parse(data))
                return
            }
            ApiCall({ url: '/api/subcategories/' + parent, method: 'get' })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    getLatestArticles(user) {
        return new Promise((resolve, reject) => {
            ApiNoCacheCall({
                url: '/api/user/getlatest/' + user,
                method: 'get',
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setLatestArticles(user, article) {
        return new Promise((resolve, reject) => {
            ApiCall({
                url: '/api/user/setlatest/' + user + '/' + article,
                method: 'get',
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    getMainCategory(category) {
        return new Promise((resolve, reject) => {
            ApiCall({
                url: '/maincategory/' + category,
                method: 'get',
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getScore(user) {
        return new Promise((resolve, reject) => {
            ApiNoCacheCall({ url: '/api/user/getscore/' + user, method: 'get' })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    getCategory(category) {
        return new Promise((resolve, reject) => {
            ApiCall({
                url: '/taxonomy/term/' + category + '?_format=json',
                method: 'get',
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    getTag(id) {
        return new Promise((resolve, reject) => {
            ApiCall({
                url: '/api/tag/' + id,
                method: 'get',
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    getSearch(query) {
        return new Promise((resolve, reject) => {
            ApiCall({
                url: '/search?query=' + query,
                method: 'get',
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    getArticle(article) {
        return new Promise((resolve, reject) => {
            ApiCall({
                url: '/article/' + article,
                method: 'get',
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    switchObjectif(article, obj, user) {
        return new Promise((resolve, reject) => {
            ApiCall({
                url: '/user/switchobj/' + article + '/' + obj + '/' + user,
                method: 'get',
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    switchFavorite(article, user) {
        return new Promise((resolve, reject) => {
            ApiCall({
                url: '/user/switchfavorrite/' + article + '/' + user,
                method: 'get',
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}
