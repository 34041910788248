<template>
  <div>
    <div v-if="!loading">
      <QuizHeader :quiz="quiz"/>
    </div>
    <v-skeleton-loader
      class="mx-auto skeleton_header"
      type="image"
      v-if="loading"
      min-height="274"
    >
    </v-skeleton-loader>

    <v-container class="mt-n12" v-if="!loading">
      <div :class="article.category.color + '_color'">
        <v-card tile class="pt-md-6 px-lg-12 pb-12">
          <v-container class="pa-0">
              sdfg
          </v-container>
        </v-card>

      </div>
    </v-container>

  </div>
</template>

<script>
import quizHeader from '@/components/QuizHeader'
import ApiCall from '@/utils/api'
import api from '@/services/api'

export default {
  name: 'quiz',
  props:['id'],
  data() {
    return {
      userpercent: 0,
      loading: true,
      quiz:{}
    }
  },
  created() {
    ApiCall({ url: '/api/quiz/' + this.id , method: 'get' })
    .then(
      response => {
        this.quiz = response.data
        console.log(this.quiz)
      })
  },
  components: {
    quizHeader
  }
}
</script>
<style lang="scss">
.skeleton_homesmallteaser {
  .v-skeleton-loader__card .v-skeleton-loader__image {
    border-radius: 5px;
  }
  .v-skeleton-loader__image {
    height: 86px;
  }
}
.skeleton_categoryteaser {
  .v-skeleton-loader__card .v-skeleton-loader__image {
    border-radius: 5px;
  }
  .v-skeleton-loader__image {
    height: 174px;
  }
}
.skeleton_search {
  border-radius: 50px;

  .v-skeleton-loader__card .v-skeleton-loader__image {
    border-radius: 50px;
  }
  .v-skeleton-loader__image {
    height: 48px;
  }
}
.search-container {
  border-radius: 50px;
}
</style>
