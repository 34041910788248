/* eslint-disable promise/param-names */
import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT
} from '../actions/auth'

import { USER_REQUEST } from '../actions/user'

import ApiCall from '@/utils/api'

const state = {
  token: localStorage.getItem('user-token') || '',
  status: '',
  hasLoadedOnce: false
}

const getters = {
  isAuthenticated: state => !!state.token,
  AuthStatus: state => state.status
}

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)

      var authFormData = new FormData()
      authFormData.append('grant_type', 'password')
      authFormData.append('client_id', 'fd9cc4b2-8afd-42f5-9789-384732befce8')
      authFormData.append('client_secret', 'yla-aasv/*ica)')
      authFormData.append('scope', 'api')
      authFormData.append('username', 'api@bluesystem.ch')
      authFormData.append('password', '12345677')

      ApiCall({ url: '/oauth/token', formData: authFormData, method: 'post' })
        .then(resp => {
          
          localStorage.setItem('username', user.username)
          localStorage.setItem('user-token', resp.data.access_token)

          commit(AUTH_SUCCESS, resp.data.access_token)

          var userFormData = new FormData()
          userFormData.append('username', user.username)
          userFormData.append('password', user.password)

          dispatch(USER_REQUEST, userFormData)
            .then(resp => {
              resolve(resp)
            })
            .catch(err => {
              commit(AUTH_ERROR, err)
              localStorage.removeItem('user-token')
              reject(err)
            })
        })
        .catch(err => {
          commit(AUTH_ERROR, err)
          localStorage.removeItem('user-token')
          reject(err)
        })
    })
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      commit(AUTH_LOGOUT)
      localStorage.removeItem('user-token')
      resolve()
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, token) => {
    state.status = 'success'
    state.token = token
    state.hasLoadedOnce = true
  },
  [AUTH_ERROR]: state => {
    state.hasLoadedOnce = true
  },
  [AUTH_LOGOUT]: state => {
    state.token = ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
