var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"py-10",attrs:{"tile":"","outlined":"","color":_vm.article.category.color}},[_c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":6}},[_c('v-list-item',{staticClass:"pl-0"},[_c('v-list-item-content',[_c('v-card-title',{staticClass:"white--text pa-0"},[_c('h1',{staticClass:"title-header",domProps:{"innerHTML":_vm._s(_vm.article.category.title)}})])],1)],1)],1),(_vm.article.parent)?_c('v-col',{attrs:{"cols":"12","md":6}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(_vm.article.parent)?_c('v-card',{staticClass:"d-inline-block",attrs:{"to":{
                                    name: 'article',
                                    params: { id: _vm.article.parent.id },
                                },"width":"100%"}},[_c('v-container',{staticClass:"pa-2"},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2","align":"center"}},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',[_c('v-icon',{attrs:{"medium":"","color":_vm.article.category
                                                                .color}},[_vm._v("mdi-arrow-left")])],1)],1)],1),_c('v-col',{staticClass:"py-0 pl-0 pr-2",attrs:{"cols":"10"}},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"mb-1 pink--text font-weight-medium",class:_vm.article.category
                                                                .color +
                                                                '--text',domProps:{"innerHTML":_vm._s(
                                                            _vm.$t(
                                                                'general.buttons.article_parent'
                                                            )
                                                        )}}),_c('v-list-item-subtitle',{staticClass:"grey--text text-truncate"},[_vm._v(" "+_vm._s(_vm.article.parent .title)+" ")])],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)],1):_c('v-col',{attrs:{"cols":"12","md":6}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(_vm.previous)?_c('v-card',{staticClass:"d-inline-block",attrs:{"to":{
                                    name: 'article',
                                    params: { id: _vm.previous.id },
                                },"width":"100%"}},[_c('v-container',{staticClass:"pa-2"},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2","align":"center"}},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',[_c('v-icon',{attrs:{"medium":"","color":_vm.article.category
                                                                .color}},[_vm._v("mdi-arrow-left")])],1)],1)],1),_c('v-col',{staticClass:"py-0 pl-0 pr-2",attrs:{"cols":"10"}},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"mb-1 pink--text font-weight-medium",class:_vm.article.category
                                                                .color +
                                                                '--text',domProps:{"innerHTML":_vm._s(
                                                            _vm.$t(
                                                                'general.buttons.article_prev'
                                                            )
                                                        )}}),_c('v-list-item-subtitle',{staticClass:"grey--text text-truncate"},[_vm._v(" "+_vm._s(_vm.previous.title)+" ")])],1)],1)],1)],1)],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.next)?_c('v-card',{staticClass:"d-inline-block",attrs:{"to":{
                                    name: 'article',
                                    params: { id: _vm.next.id },
                                },"width":"100%"}},[_c('v-container',{staticClass:"pa-2"},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"py-0 pl-4 pr-0",attrs:{"cols":"10"}},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"mb-1 pink--text font-weight-medium",class:_vm.article.category
                                                                .color +
                                                                '--text',domProps:{"innerHTML":_vm._s(
                                                            _vm.$t(
                                                                'general.buttons.article_next'
                                                            )
                                                        )}}),_c('v-list-item-subtitle',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.next.title)+" ")])],1)],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2","align":"center"}},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',[_c('v-icon',{attrs:{"medium":"","color":_vm.article.category
                                                                .color}},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }